<template>
  <div ref=orSnapshot class="event-snapshot-or" :class="{ 'print-screen': isPrintScreen }">
    <canvas 
      :id="canvasId" 
      class="canvas" 
      @wheel="handleWheel" 
      @mousedown="onMouseDown"
      @mousemove="onMouseMove"
      @mouseup="onMouseUp"
    ></canvas>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { fabric } from 'fabric'

export default {
  name: 'EventSnapshotOr',
  props: {
    event: {
      type: Object,
      required: true,
    },
    isPrintScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      canvas: null,
      isDragging: false,
      lastPosX: 0,
      lastPosY: 0,
      scaledW: 0, 
      scaledH: 0,
      fitScale: 1.0,
      classIcon: {
        1: require('@/assets/icons/person.svg'),
        2: require('@/assets/icons/car.svg'),
        3: require('@/assets/icons/bicycle.svg'),
        4: require('@/assets/icons/truck.svg'),
        5: require('@/assets/icons/bus.svg'),
      },
      areaFillColor: {
        area1: '#F941444D',
        area2: '#F99D414D',
        area3: '#4361EE4D',
      },
      objStrokeColor: {
        1: '#F94144',
        2: '#41F9DA',
        3: '#7961FF',
        4: '#F9ED41',
        5: '#E3FFC1',
      },
    }
  },
  computed: {
    ...mapState('historyOr', ['objsClassCount', 'detectAreas']),
    canvasId() {
      return this.isPrintScreen ? `orCanvasPrintScreen` : `orCanvas`
    },
  },
  watch: {
    'event.id'() {
      this.initCanvas()
    },
    objsClassCount: {
      handler() {
        this.initCanvas()
      },
      deep: true,
    },
    detectAreas: {
      handler() {
        this.initCanvas()
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initCanvas()
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    getScaledImageSize(img) {
      const origWidth = img.width
      const origHeight = img.height
      const divWidth = this.$refs.orSnapshot?.clientWidth
      const divHeight = this.$refs.orSnapshot?.clientHeight
      const fitScale = Math.min(divWidth / origWidth, divHeight / origHeight)
      const scaledW = origWidth * fitScale
      const scaledH = origHeight * fitScale

      return [scaledW, scaledH, fitScale]
    },
    initCanvas() {
      if (!this.canvas)
        this.canvas = new fabric.Canvas(this.canvasId)

      const img = new Image()
      img.src = this.event.snapshotUrl
      img.crossOrigin = 'Anonymous'
      img.onload = () => {
        [ this.scaledW, this.scaledH, this.fitScale ] = this.getScaledImageSize(img)

        // 調整canvas大小
        this.canvas.setWidth(this.scaledW)
        this.canvas.setHeight(this.scaledH)

        const image = new fabric.Image(img)
        image.scale(this.fitScale)

        this.canvas.add(image)

        // 繪製警戒區域
        this.drawAreaPolygon()

        // 繪製辨識物件
        this.drawObjectsRect()
        this.canvas.renderAll()
      }
    },
    drawAreaPolygon() {
      Object.keys(this.event.metadata).forEach(key => {
        let area = this.event.metadata[key]
        if (area.length > 0 && this.detectAreas[key].isShow) {
          let points = []
          area.forEach(point => {
            points.push({
              x: point.x * this.scaledW,
              y: point.y * this.scaledH,
            })
          })
          let polygon = new fabric.Polygon(points, {
            name: 'area',
            fill: this.areaFillColor[key],
            stroke: 'transparent',
            strokeWidth: 1.5,
            selectable: false,
          })
          this.canvas.add(polygon)
        }
      })
    },
    drawObjectsRect() {
      this.event.objects.forEach(obj => {
        if (!this.objsClassCount[obj.class].isShow) return
        var rect = new fabric.Rect({
          name: 'obj',
          left: obj.x * this.scaledW,
          top: obj.y * this.scaledH,
          fill: 'transparent',
          width: obj.w * this.scaledW,
          height: obj.h * this.scaledH,
          stroke: this.objStrokeColor[obj.class],
          strokeWidth: 1
        });

        this.canvas.add(rect);

        let score = Math.floor(obj.score * 100) + '%'

        var text = new fabric.Text(score, {
          left: rect.left + 22, // 文本位置稍微调整以适应矩形
          top: rect.top - 23,
          fill: '#ffffff',
          fontSize: 15,
          letterSpacing: 0.5,
        });

        this.canvas.add(text);

        fabric.loadSVGFromURL(this.classIcon[obj.class], (objects, options) => {
          var icon = fabric.util.groupSVGElements(objects, options);
          icon.set({
            width: 16,
            height: 16 ,
            left: rect.left, // 根据需要调整位置
            top: rect.top - 22,
          });
          this.canvas.add(icon);
        });
      })
    },
    handleWheel(e) {
      var delta = e.deltaY;
      var zoom = this.canvas.getZoom(); 
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;

      // 檢查是否會超出界限
      if (this.canvas.getWidth() * zoom < this.canvas.width ||
          this.canvas.getHeight() * zoom < this.canvas.height) {
        return;
      }  

      this.canvas.zoomToPoint({ x: e.offsetX, y: e.offsetY }, zoom)
      e.preventDefault();
      e.stopPropagation();
    },
    onMouseDown(e) {
      this.isDragging = true
      this.lastPosX = e.clientX
      this.lastPosY = e.clientY
    },
    onMouseMove(e) {
      if (this.isDragging) {
        const vpt = this.canvas.viewportTransform
        vpt[4] += e.clientX - this.lastPosX
        vpt[5] += e.clientY - this.lastPosY
        this.canvas.requestRenderAll()
        this.lastPosX = e.clientX
        this.lastPosY = e.clientY
      }
    },
    onMouseUp() {
      this.canvas.setViewportTransform(this.canvas.viewportTransform)
      this.isDragging = false
    },
    handleResize() {
      this.initCanvas()
    },
  },
}
</script>

<style lang="scss" scoped>
.event-snapshot-or {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 1px solid #4A5C7880;

  &.print-screen {
    min-height: 400px;
  }
}

.canvas {
  // position: absolute;
  // width: 100%;
  // height: 100%;
  z-index: 10;
  // background-color: transparent;
}
</style>